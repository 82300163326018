import { useFormikContext } from 'formik'
import { Paper, Select, TextInput } from '../../../components/common/Form'
import { InputFormProps, MAX_MAX_BITRATE_MBPS } from '../Edit/InputForm'
import { useEffect, useState } from 'react'

export const TranscodingSection = () => {
  const { values } = useFormikContext<InputFormProps>()
  const [isPixelFormat, setIsPixelFormat] = useState(false)
  const [isOverlay, setIsOverlay] = useState(false)
  const [isResolution, setIsResolution] = useState(false)

  useEffect(() => {
    if (
      values?.deriveFrom?.ingestTransform &&
      values.deriveFrom.ingestTransform.type === 'transcode' &&
      values.deriveFrom.ingestTransform.ffmpegParams
    ) {
      setIsOverlay(values.deriveFrom.ingestTransform.ffmpegParams.overlay ? true : false)
      setIsResolution(values.deriveFrom.ingestTransform.ffmpegParams.resolution ? true : false)
      setIsPixelFormat(values.deriveFrom.ingestTransform.ffmpegParams.pixelFormat ? true : false)
    }
  }, [values?.deriveFrom?.ingestTransform])

  return (
    <Paper title="Transcoding">
      <TextInput
        label="Bitrate (Mbps)"
        name="transcodeBitrateMbps"
        noNegative
        validators={{
          number: {
            lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
            greaterThan: 0,
            message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
          },
        }}
        type="number"
        tooltip="Choose the compression level by selecting a bitrate for the transformed input"
      />
      <Select
        label="Framerate"
        name="deriveFrom.ingestTransform.ffmpegParams.framerate"
        options={['25', '29.97', '50', '59.94'].map((framerate) => ({ name: framerate, value: framerate }))}
        tooltip="Convert framerate up or down, dropping extra frames or duplicating intermediate frames"
      />
      <Select
        label="Codec"
        name="deriveFrom.ingestTransform.ffmpegParams.codec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'H.264 (AVC)', value: 'h264' },
          { name: 'H.265 (HEVC)', value: 'h265' },
        ]}
        tooltip="Change codec using hardware acceleration"
      />
      <Select
        label="Audio codec"
        name="deriveFrom.ingestTransform.ffmpegParams.audioCodec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'AAC', value: 'aac' },
          { name: 'MP2', value: 'mp2' },
          { name: 'AC3', value: 'ac3' },
        ]}
        tooltip="Change audio codec"
      />
      <Select
        label={!isResolution ? 'Pixel format' : 'Pixel format (cannot be used with resolution scaling)'}
        name="deriveFrom.ingestTransform.ffmpegParams.pixelFormat"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'YUV 4:2:0 Progressive', value: 'yuv420p' },
          { name: 'YUV 4:2:2 Progressive', value: 'yuv422p', disabled: true },
          { name: 'YUV 4:4:4 Progressive', value: 'yuv444p' },
        ]}
        tooltip="Pixel format choice depends on the hardware accelerator's capability. Cannot be used with resolution scaling"
        disabled={isResolution}
      />
      <Select
        label={!isResolution ? 'Overlay' : 'Overlay (cannot be used with resolution scaling)'}
        name="deriveFrom.ingestTransform.ffmpegParams.overlay"
        options={[
          { name: 'None', value: '' },
          { name: 'Net Insight demo', value: 'demo' },
        ]}
        tooltip="Add a channel logo or other overlays to the transformed input. Cannot be used with resolution scaling"
        disabled={isResolution}
      />
      <Select
        label={!(isOverlay || isResolution) ? 'Resolution scaling' : 'Resolution scaling (cannot be used with overlay)'}
        name="deriveFrom.ingestTransform.ffmpegParams.resolution"
        options={[
          { name: 'Keep original', value: '' },
          { name: '2160p', value: '3840:2160' },
          { name: '1080p', value: '1920:1080' },
          { name: '720p', value: '1280:720' },
        ]}
        tooltip="Scale the resolution of the transformed input (Only available with NVIDIA accelerators). Cannot be used with overlay or pixel format"
        disabled={isOverlay || isPixelFormat}
      />
    </Paper>
  )
}
